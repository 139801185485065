<template>
    <div>
        <Toast />
        <div class="card">
            <h5>Importación de Lecturas de Contadores</h5>        
        </div>        
        <div class="card">
            <Steps :model="items" :readonly="true" />
        </div>

        <router-view v-slot="{Component}" :key="keyUpdate" :formData="condiciones" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete">
            <keep-alive>
                <component :is="Component" />
            </keep-alive>
        </router-view>

    </div>
</template>
<script>
import { CURRENT_APP_MODE } from '../../../service/constants';

export default {
    data() {
        return {
            appMode: CURRENT_APP_MODE,
            keyUpdate: 0,
            condiciones: {
                id_perfil: '',
                id_moneda: '',
                moneda_simbolo: '',
                tipo: 0,
                procesados: 0,
                total_procesado: 0.0,
                errores: false,
                archivo: '',
                lecturas: []
			},                     
            items: [{
                label: 'Selección de Perfil',
                to: '/impcontlecturas',
            },
            {
                label: 'Revisión',
                to: '/impcontrevision'
            },      
            {
                label: 'Resumen',
                to: '/impcontresumen'
            }            
            ],
            formObject: {}
        }
    },
    methods: {
        nextPage(event) {
            this.condiciones.id_perfil = event.condiciones.id_perfil;
            this.condiciones.id_moneda = event.condiciones.id_moneda;
            this.condiciones.moneda_simbolo = event.condiciones.moneda_simbolo;
            this.condiciones.tipo = event.condiciones.tipo;
            this.condiciones.procesados = event.condiciones.procesados;
            this.condiciones.total_procesado = event.condiciones.total_procesado;
            this.condiciones.archivo = event.condiciones.archivo;
            this.condiciones.errores = event.condiciones.errores;
            this.condiciones.lecturas = event.condiciones.lecturas;

            let path = this.items[event.pageIndex + 1].to;
            
            if((event.pageIndex == 1) || (event.pageIndex == 2)) { //Si es la pagina de revision se debe actualizar para que se recalcule
                this.keyUpdate++;
            }

            this.$router.push(path);
        },
        prevPage(event) {
            this.$router.push(this.items[event.pageIndex - 1].to);
        },
        complete() {
            this.$toast.add({severity:'success', summary:'Lecturas importadas y procesados!', detail: ''});
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>